// const firebaseConfig = {
//   apiKey: "AIzaSyBtZcEG4vcJHBa3UlDEtPpTJ3D5TNpkzAs",
//   authDomain: "collect2play-5c3d4.firebaseapp.com",
//   databaseURL: "https://collect2play-5c3d4-default-rtdb.firebaseio.com",
//   projectId: "collect2play-5c3d4",
//   storageBucket: "collect2play-5c3d4.appspot.com",
//   messagingSenderId: "432235957600",
//   appId: "1:432235957600:web:f3c383932428a00cec2205",
//   measurementId: "G-WY68V7QXMR",
// };


const firebaseConfig = {
  apiKey: "AIzaSyDwAJYcCk3aLV17WFDqO3WKBs975-cAzC4",
  authDomain: "testc2p-7dfb1.firebaseapp.com",
  projectId: "testc2p-7dfb1",
  storageBucket: "testc2p-7dfb1.appspot.com",
  messagingSenderId: "173765640468",
  appId: "1:173765640468:web:f92b376ad7b12869bd4201",
  measurementId: "G-WF3GP4662P"
};

export default firebaseConfig;
